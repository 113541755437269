<template>
  <div class="test">
    <ColorPalette></ColorPalette>
  </div>
</template>

<script>
// @ is an alias to /src
import ColorPalette from '@/components/tools/ColorPalette'

export default {
  name: 'Test',
  components: {
    ColorPalette
  }
}
</script>
