<template>
  <div>
    <h1>LCH</h1>
    <label for="lch-l">Lightness {{ lchL }} </label>
    <input v-model="lchL" id="lch-l" type="range" min="0" max="100" />
    <label for="lch-c">Chroma {{ lchC }} </label>
    <input v-model="lchC" id="lch-c" type="range" min="0" max="100" />
    <label for="lch-h">Hue {{ lchH }} </label>
    <input v-model="lchH" id="lch-h" type="range" min="0" max="360" />
    <div class="color-display" :style="{ backgroundColor: lchColor }"></div>
  </div>

  <div>
    <h1>Custom colour space</h1>
    <label for="customCol-l">Lightness {{ customCol1 }} </label>
    <input v-model="customCol1" id="customCol-l" type="range" min="0" max="100" />
    <label for="customCol-c">Chroma {{ customCol2 }} </label>
    <input v-model="customCol2" id="customCol-c" type="range" min="0" max="100" />
    <label for="customCol-h">Hue {{ customCol3 }} </label>
    <input v-model="customCol3" id="customCol-h" type="range" min="0" max="360" />
    <label for="customCol-b">Base {{ customCol4 }} </label>
    <input v-model="customCol4" id="customCol-b" type="range" min="0" max="100" />
    <div class="color-display" :style="{ backgroundColor: customColor }"></div>
  </div>

  <div class="palettes">
    <ColorBlock v-for="(block, idx) in blocks3" :color="lchb(block)" :key="idx" />
  </div>
  <div class="palettes">
    <ColorBlock v-for="(block, idx) in blocks" :color="lchb(block)" :key="idx" />
  </div>
  <div class="palettes">
    <ColorBlock v-for="(block, idx) in blocks2" :color="lchb(block)" :key="idx" />
  </div>


  <div class="palettes">
    <ColorBlock v-for="(block, idx) in blocks4" :color="lchb(block)" :key="idx" />
  </div>


</template>

<script>
import space from 'color-space'
import { formatRgb, clampChroma, oklab, oklch, rgb } from 'culori'
import customColour from '@/color-space/color-space-1'
// import lchToRgb from '@/color-space/lch-rgb'
import lchToRgbString from '@/color-space/lch-rgb-flat.js'
import ColorBlock from '@/components/elements/ColorBlock'

export default {
  components: { ColorBlock },
  data () {
    return {
      lchL: '50',
      lchC: '50',
      lchH: '180',
      customCol1: '50',
      customCol2: '50',
      customCol3: '180',
      customCol4: '0',
      blocks: [
        [5,  60, 0, 0],
        [10, 60, 0, 0],
        [20, 60, 0, 0],
        [30, 60, 0, 0],
        [40, 60, 0, 0],
        [50, 60, 0, 0],
        [60, 60, 0, 0],
        [70, 60, 0, 0],
        [80, 60, 0, 0],
        [90, 60, 0, 0],
        [95, 60, 0, 0]
      ],
      blocks2: [
        [5,  60, 0, 100],
        [10, 60, 0, 100],
        [20, 60, 0, 100],
        [30, 60, 0, 100],
        [40, 60, 0, 100],
        [50, 60, 0, 100],
        [60, 60, 0, 100],
        [70, 60, 0, 100],
        [80, 60, 0, 100],
        [90, 60, 0, 100],
        [95, 60, 0, 100]
      ],
      blocks3: [
        [5,  20, 180, 0],
        [10, 20, 180, 0],
        [20, 20, 180, 0],
        [30, 20, 180, 0],
        [40, 20, 180, 0],
        [50, 20, 180, 0],
        [60, 20, 180, 0],
        [70, 20, 180, 0],
        [80, 20, 180, 0],
        [90, 20, 180, 0],
        [95, 20, 180, 0]
      ],
      blocks4: [
        [5,  100, 280, 21],
        [10, 100, 280, 21],
        [20, 100, 280, 21],
        [30, 100, 280, 21],
        [40, 100, 280, 21],
        [50, 100, 280, 21],
        [60, 100, 280, 21],
        [70, 100, 280, 21],
        [80, 100, 280, 21],
        [90, 100, 280, 21],
        [95, 100, 280, 21]
      ]
    }
  },
  computed: {
    customColor () {
      const colToLch = customColour([this.customCol1, this.customCol2, this.customCol3, this.customCol4])
      return colToLch
      //const col = `lch(${colToLch[0]}% ${colToLch[1]} ${colToLch[2]})`
      //return formatRgb(col)
    },
    lchColor () {
      //const col = space.lchuv.rgb([parseInt(this.lchL), parseInt(this.lchC), parseInt(this.lchH)])
      //console.log(col)
      //console.log(space.rgb.lab([255, 0, 0]))
      //const lab = this.lchToLab([this.lchL, this.lchC, this.lchH])
      //console.log(lab)
      //const col2 =  space.lab.rgb(lab)
      //console.log(col2)
      // return this.toRgbString(col)

      //const col4 = space.oklch.rgb()

      // const col3 = clampChroma(`lch(${this.lchL}% ${this.lchC} ${this.lchH})`)
      //const col3 = `oklch(${this.lchL}% ${this.lchC} ${this.lchH})`
      //const col3 = oklch({ l: this.lchL, c: this.lchC, h: this.lchH })
      //console.log(col3, rgb(col3))
      // const col3 =`lch(${this.lchL}% ${this.lchC} ${this.lchH})`
      //return formatRgb(col3)
      return lchToRgbString({ l: this.lchL, c: this.lchC, h: this.lchH })
    }
  },
  methods: {
    lchb(arr) {
      return customColour(arr)
    },
    toRgbString (input) {
      return `rgb(${input[0]}, ${input[1]}, ${input[2]})`
    },
    lchToLab (lch) {
      lch = lch.map(num => parseInt(num))
      //console.log(lch)
      const a = Math.cos(lch[2]) * lch[1]
      const b = Math.sin(lch[2]) * lch[1]
      //console.log(a, b)
      return [lch[0], a, b]
    }
  }
}
</script>

<style scoped>
input {
  display: inline-block;
  margin: 0 50px 0 10px;
  padding: 0;
}
label {
  display: inline-block;
  text-align: right;
  width: 100px;
}
.color-display {
  margin-top: 20px;
  margin-bottom: 70px;
  height: 50px;
  width: 100%;
}
h1 {
  margin-top: 30px;
}
.palettes {
  margin-top: 0px;
}
</style>
