<template>
  <!--<ColorList :palette="'shades'" :colors="['6B7280']" :amount="9"></ColorList>-->
<!--
  <ColorList :palette="'shades'" :colors="['purple', 'orange']" :amount="amount" :brightness="brightness"></ColorList>
  <ColorList :palette="'shades'" :colors="['orange', 'purple']" :amount="amount" :brightness="brightness"></ColorList>
  <ColorList :palette="'shades'" :colors="['purple']" :amount="amount" :brightness="brightness"></ColorList>
  <ColorList :palette="'shades'" :colors="['yellow', 'purple']" :amount="amount" :brightness="brightness"></ColorList>
  <ColorList :palette="'shades'" :colors="['yellow']" :amount="amount" :brightness="brightness"></ColorList>
  <ColorList :palette="'shades'" :colors="['yellow', 'turquoise']" :amount="amount" :brightness="brightness"></ColorList>
  <ColorList :palette="'shades'" :colors="['turquoise']" :amount="amount" :brightness="brightness"></ColorList>
  <ColorList :palette="'shades'" :colors="['turquoise', 'green']" :amount="amount" :brightness="brightness"></ColorList>
  <ColorList :palette="'shades'" :colors="['green']" :amount="amount" :brightness="brightness"></ColorList>
  <ColorList :palette="'shades'" :colors="['yellow', 'red']" :amount="amount" :brightness="brightness"></ColorList>
  <ColorList :palette="'shades'" :colors="['yellow']" :amount="amount" :brightness="brightness"></ColorList>
  <ColorList :palette="'shades'" :colors="['yellow', 'blue']" :amount="amount" :brightness="brightness"></ColorList>
  <ColorList :palette="'shades'" :colors="['blue']" :amount="amount" :brightness="brightness"></ColorList>
  <ColorList :palette="'shades'" :colors="['red', 'blue']" :amount="amount" :brightness="brightness"></ColorList>
  <ColorList :palette="'shades'" :colors="['red']" :amount="amount" :brightness="brightness"></ColorList>
-->


  <ColorList :palette="'shades'" :colors="['#323538']" :amount="amount" :brightness="brightness"></ColorList>
  <ColorList :palette="'shades'" :colors="['#171c24']" :amount="amount" :brightness="brightness"></ColorList>
  <ColorList :palette="'shades'" :colors="['#00fff2']" :amount="amount" :brightness="brightness"></ColorList>
  <ColorList :palette="'shades'" :colors="['#00ffe1']" :amount="amount" :brightness="brightness"></ColorList>
  <ColorList :palette="'shades'" :colors="['#00ffd5']" :amount="amount" :brightness="brightness"></ColorList>
  <ColorList :palette="'shades'" :colors="['#00ffc7']" :amount="amount" :brightness="brightness"></ColorList>


  <ColorList :palette="'shades'" :colors="['#A838C9']" :amount="amount" :brightness="brightness"></ColorList>
  <ColorList :palette="'shades'" :colors="['#C500FF']" :amount="amount" :brightness="brightness"></ColorList>

  <ColorList :palette="'shades'" :colors="['#8DAB89']" :amount="amount" :brightness="brightness"></ColorList>
  <ColorList :palette="'shades'" :colors="['#83B47D']" :amount="amount" :brightness="brightness"></ColorList>
  <ColorList :palette="'shades'" :colors="['#71BF68']" :amount="amount" :brightness="brightness"></ColorList>
  <ColorList :palette="'shades'" :colors="['#5FC853']" :amount="amount" :brightness="brightness"></ColorList>
  <ColorList :palette="'shades'" :colors="['#4DD33D']" :amount="amount" :brightness="brightness"></ColorList>
  <ColorList :palette="'shades'" :colors="['#38DF24']" :amount="amount" :brightness="brightness"></ColorList>
  <ColorList :palette="'shades'" :colors="['#22FA08']" :amount="amount" :brightness="brightness"></ColorList>
  <ColorList :palette="'shades'" :colors="['#7C828A']" :amount="amount" :brightness="brightness"></ColorList>

  <ColorList :palette="'shades'" :colors="['#483D8B']" :amount="amount" :brightness="brightness"></ColorList>
  <ColorList :palette="'shades'" :colors="['#000000']" :amount="amount" :brightness="brightness"></ColorList>
  <ColorList :palette="'shades'" :colors="['#008B8B']" :amount="amount" :brightness="brightness"></ColorList>
  <ColorList :palette="'shades'" :colors="['#DC143C']" :amount="amount" :brightness="brightness"></ColorList>

  <ColorList :palette="'shades'" :colors="['#351E20']" :amount="amount" :brightness="brightness"></ColorList>
  <ColorList :palette="'shades'" :colors="['#27272E']" :amount="amount" :brightness="brightness"></ColorList>
  <ColorList :palette="'shades'" :colors="['#272E28']" :amount="amount" :brightness="brightness"></ColorList>
  <ColorList :palette="'shades'" :colors="['#022605']" :amount="amount" :brightness="brightness"></ColorList>
  <ColorList :palette="'shades'" :colors="['#455046']" :amount="amount" :brightness="brightness"></ColorList>
  <ColorList :palette="'shades'" :colors="['#7D967F']" :amount="amount" :brightness="brightness"></ColorList>
  <ColorList :palette="'shades'" :colors="['#8D7D96']" :amount="amount" :brightness="brightness"></ColorList>
  <ColorList :palette="'shades'" :colors="['#D05248']" :amount="amount" :brightness="brightness"></ColorList>
  <ColorList :palette="'shades'" :colors="['#916d6d']" :amount="amount" :brightness="brightness"></ColorList>


  <ColorList :palette="'shades'" :colors="['mintcream']" :amount="amount" :brightness="brightness"></ColorList>
  <ColorList :palette="'shades'" :colors="['aliceblue']" :amount="amount" :brightness="brightness"></ColorList>
  <ColorList :palette="'shades'" :colors="['crimson']" :amount="amount" :brightness="brightness"></ColorList>
  <ColorList :palette="'shades'" :colors="['indigo']" :amount="amount" :brightness="brightness"></ColorList>
  <ColorList :palette="'shades'" :colors="['lightsteelblue']" :amount="amount" :brightness="brightness"></ColorList>
  <ColorList :palette="'shades'" :colors="['rosybrown']" :amount="amount" :brightness="brightness"></ColorList>
  <ColorList :palette="'shades'" :colors="['thistle']" :amount="amount" :brightness="brightness"></ColorList>
  <ColorList :palette="'shades'" :colors="['chocolate']" :amount="amount" :brightness="brightness"></ColorList>
  <ColorList :palette="'shades'" :colors="['gold']" :amount="amount" :brightness="brightness"></ColorList>
  <ColorList :palette="'shades'" :colors="['fuchsia']" :amount="amount" :brightness="brightness"></ColorList>



  <ColorList :palette="'shades'" :colors="['hsl(0, 0%, 50%)']" :amount="amount" :brightness="brightness"></ColorList>
  <ColorList :palette="'shades'" :colors="['hsl(  0, ' + saturation + ', ' + lightness + ' )']" :amount="amount" :brightness="brightness"></ColorList>
  <ColorList :palette="'shades'" :colors="['hsl( 30, ' + saturation + ', ' + lightness + ' )']" :amount="amount" :brightness="brightness"></ColorList>
  <ColorList :palette="'shades'" :colors="['hsl( 60, ' + saturation + ', ' + lightness + ' )']" :amount="amount" :brightness="brightness"></ColorList>
  <ColorList :palette="'shades'" :colors="['hsl( 90, ' + saturation + ', ' + lightness + ' )']" :amount="amount" :brightness="brightness"></ColorList>
  <ColorList :palette="'shades'" :colors="['hsl(120, ' + saturation + ', ' + lightness + ' )']" :amount="amount" :brightness="brightness"></ColorList>
  <ColorList :palette="'shades'" :colors="['hsl(150, ' + saturation + ', ' + lightness + ' )']" :amount="amount" :brightness="brightness"></ColorList>
  <ColorList :palette="'shades'" :colors="['hsl(180, ' + saturation + ', ' + lightness + ' )']" :amount="amount" :brightness="brightness"></ColorList>
  <ColorList :palette="'shades'" :colors="['hsl(210, ' + saturation + ', ' + lightness + ' )']" :amount="amount" :brightness="brightness"></ColorList>
  <ColorList :palette="'shades'" :colors="['hsl(240, ' + saturation + ', ' + lightness + ' )']" :amount="amount" :brightness="brightness"></ColorList>
  <ColorList :palette="'shades'" :colors="['hsl(270, ' + saturation + ', ' + lightness + ' )']" :amount="amount" :brightness="brightness"></ColorList>
  <ColorList :palette="'shades'" :colors="['hsl(300, ' + saturation + ', ' + lightness + ' )']" :amount="amount" :brightness="brightness"></ColorList>
  <ColorList :palette="'shades'" :colors="['hsl(330, ' + saturation + ', ' + lightness + ' )']" :amount="amount" :brightness="brightness"></ColorList>
  <ColorList :palette="'shades'" :colors="['hsl(360, ' + saturation + ', ' + lightness + ' )']" :amount="amount" :brightness="brightness"></ColorList>
  <ColorList :palette="'shades'" :colors="['hsl(0, 0%, 50%)']" :amount="amount" :brightness="brightness"></ColorList>

  <ColorList :palette="'shades'" :colors="['#10B981']" :amount="amount" :brightness="brightness"></ColorList>
  <ColorList :palette="'shades'" :colors="['#EF4444']" :amount="amount" :brightness="brightness"></ColorList>
  <ColorList :palette="'shades'" :colors="['#F59E0B']" :amount="amount" :brightness="brightness"></ColorList>
  <ColorList :palette="'shades'" :colors="['#c39341']" :amount="amount" :brightness="brightness"></ColorList>
  <ColorList :palette="'shades'" :colors="['#efff00']" :amount="amount" :brightness="brightness"></ColorList>
  <ColorList :palette="'shades'" :colors="['#3B82F6']" :amount="amount" :brightness="brightness"></ColorList>
  <ColorList :palette="'shades'" :colors="['#6366F1']" :amount="amount" :brightness="brightness"></ColorList>
  <ColorList :palette="'shades'" :colors="['#8B5CF6']" :amount="amount" :brightness="brightness"></ColorList>

  <ColorList :palette="'shades'" :colors="['purple']" :amount="amount" :brightness="brightness"></ColorList>
  <ColorList :palette="'shades'" :colors="['#964c96']" :amount="amount" :brightness="brightness"></ColorList>
  <ColorList :palette="'shades'" :colors="['coral']" :amount="amount" :brightness="brightness"></ColorList>
  <ColorList :palette="'shades'" :colors="['red']" :amount="amount" :brightness="brightness"></ColorList>
  <ColorList :palette="'shades'" :colors="['green']" :amount="amount" :brightness="brightness"></ColorList>
  <ColorList :palette="'shades'" :colors="['blue']" :amount="amount" :brightness="brightness"></ColorList>
  <ColorList :palette="'shades'" :colors="['whitesmoke']" :amount="amount" :brightness="brightness"></ColorList>
  <ColorList :palette="'shades'" :colors="['#EC4899']" :amount="amount" :brightness="brightness"></ColorList>

</template>

<script>
import ColorList from '@/components/modules/ColorList'
export default {
  name: 'ColorPalette',
  components: { ColorList },
  data () {
    return {
      saturation: '70%',
      lightness: '50%',
      amount: 4,
      brightness: 0,
      cols: ['purple', 'blue', 'green']
    }
  }
}
</script>

<style scoped>

</style>
