import lchToRgbString from '@/color-space/lch-rgb-flat'

export default function (cols) {
  console.log(cols)
  let [col1, col2, col3, col4] = cols.map(c => parseInt(c))

  /*
  if (col4 > 50) {
    col1 = col4 - col1
  } else {
    col1 = col4 + col1
  }
  */

  if (col4 > 50) {
    col1 = col1 = ((100 - col4) / 100) * col1
  } else {
    col1 = ((100 - col4) / 100) * col1+ col4
  }

  return lchToRgbString({
    l: col1,
    c: col2,
    h: col3
  })
}
